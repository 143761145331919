import React from "react"
import Layout from "../components/layout"
import { graphql } from 'gatsby'

export const query = graphql`
    {
      allAirtable(filter: {table: {eq: "Dokumentir"}, data: {Name: {eq: "Hvíta bók"}}}) {
        nodes {
          recordId
          data {
            Name
            Attachments {
              url
            }
          }
        }
      }
    }
`;


export default ({ data }) => (
  <Layout>
      <h1>Hvíta bók</h1>
      <ul>          
        {data.allAirtable.nodes.map(node => (
          <li key={node.recordId}>                                 
             <iframe title={node.data.Name} src={node.data.Attachments[0].url} width="100%" height="800px"></iframe>
          </li>
        ))}
      </ul>
  </Layout>    
)
//https://www.youtube.com/watch?v=x6IA6dfzlcQ&list=PLz8Iz-Fnk_eTpvd49Sa77NiF8Uqq5Iykx&index=4
/*


*/

